import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';
import styles from './SessionModal.css';
import classNames from 'classnames';
import SessionLogout from './SessionLogout';
import {
  logoutUtil,
  redirectionRootPath,
  refreshTokens,
} from 'lib/utils/common';
import { GET_STARTED_PATH, LOGIN_CAPTURE_PATH } from 'shared/constants/paths';
import { useOktaAuth } from '@okta/okta-react';
import LocalStorageProxy, { properties } from 'lib/localStorageProxy';
import UIActions from 'actions/UIActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SessionModal = (props) => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showContinueSession, setShowContinueSession] = useState(
    LocalStorageProxy.showContinueSession,
  );

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const accessToken = await oktaAuth.tokenManager.get('accessToken');
      console.log('checkTokenExpiration.accessToken==', accessToken);
      /*
        Possible scenarios where token is not available:
        - User is not logged in or session has expired
        - User is logged in but token was not retrieved successfully
        - Third-party cookies are disabled, preventing Okta from auto-renewing tokens via oktaAuth.authStateManager.subscribe
        - Token was cleared or local storage is cleared
        - Token manager failed to retrieve stored token

        Note: if accessToken is not available, it means that the user is not logged in or session has expired
      */
      if (!accessToken && !props.showModal && !showContinueSession) {
        props.setSessionStatus({ showModal: true });
        return;
      }

      const expiresAt = accessToken.expiresAt * 1000; // Convert seconds to milliseconds
      const currentTime = Date.now();
      const timeUntilExpiration = expiresAt - currentTime;
      /*
        validate 6 mins before expiry
        to avoid conflict to auto refresh token
      */
      const is6minsBeforeExpiry = timeUntilExpiration < 360000;
      console.log('is6minsBeforeExpiry==', is6minsBeforeExpiry);
      if (is6minsBeforeExpiry && !props.showModal) {
        props.setSessionStatus({ showModal: true });
      }
    };

    console.log(
      'checkTokenExpiration==',
      'LocalStorageProxy.token:',
      LocalStorageProxy.token,
      'props.isBrokerLogin:',
      props.isBrokerLogin,
    );
    // Check more frequently - every 20 seconds
    if (LocalStorageProxy.token && !props.isBrokerLogin) {
      const interval = setInterval(checkTokenExpiration, 20000);
      checkTokenExpiration(); // Initial check
      return () => clearInterval(interval);
    }
  }, [oktaAuth.tokenManager, props.showModal, props.isBrokerLogin]);

  useEffect(() => {
    let timer;
    if (props.showModal && props.timeLeft > 0 && !showContinueSession) {
      timer = setInterval(() => {
        props.setSessionStatus({
          ...props.sessionStatus,
          timeLeft: props.timeLeft - 1,
        });
      }, 1000);

      return () => clearInterval(timer);
    }

    if (props.timeLeft <= 0 && !showContinueSession) {
      onLogout(true, true);
    }

    return () => clearInterval(timer);
  }, [props.showModal, props.timeLeft, showContinueSession]);

  const minutes = Math.floor(props.timeLeft / 60);
  const seconds = props.timeLeft % 60;

  const onContinue = async () => {
    setIsLoading(true);
    try {
      await refreshTokens(oktaAuth);
      setIsLoading(false);
      setShowContinueSession(false);
      LocalStorageProxy.clear(properties.SHOW_CONTINUE_SESSION);
      props.setSessionStatus({
        showModal: false,
        timeLeft: 120, // Reset timer when continuing session
      });
    } catch (error) {
      console.error('Continue failed:', error);
      setIsLoading(false);
    }
  };

  const onLogout = async (isRedirectToLogin, showContinueSession = false) => {
    setIsLoading(true);
    const logoutPath = isRedirectToLogin
      ? redirectionRootPath(LOGIN_CAPTURE_PATH, true)
      : redirectionRootPath(GET_STARTED_PATH, true);

    try {
      await logoutUtil(logoutPath, oktaAuth, showContinueSession);
      props.setSessionStatus({
        showModal: false,
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const onLoginClick = () => {
    LocalStorageProxy.clear(properties.SHOW_CONTINUE_SESSION);
    setShowContinueSession(false);
    props.setSessionStatus({
      showModal: false,
    });
    history.push(LOGIN_CAPTURE_PATH);
  };

  if (!props.showModal && !showContinueSession) {
    return null;
  }

  if (showContinueSession) {
    return (
      <div className={styles.modalDialogLogout}>
        <SessionLogout onLoginClick={onLoginClick} />
      </div>
    );
  }

  return (
    <div className={styles.modalDialog}>
      <div className={styles.modalContent}>
        <h2>Your session will end soon</h2>

        <div className={styles.modalBody}>
          <p>
            For security reasons, you will be logged out in {minutes}:
            {seconds.toString().padStart(2, '0')}{' '}
            {props.timeLeft < 60 ? 'second/s' : 'minute/s'}. Select
            &apos;Continue&apos; to stay signed in.
          </p>
        </div>

        <div className={styles.modalFooter}>
          <Button
            onClick={() => onLogout(false)}
            theme='linkButtonTheme'
            className={styles.logoutBtn}
            disabled={isLoading}
          >
            Log out
          </Button>

          <Button
            onClick={onContinue}
            className={classNames('brandColor__button', styles.blockBtn)}
            theme='buttonNext'
            disabled={isLoading}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showModal: state.UISettings.sessionStatus.showModal,
    isBrokerLogin: state.UISettings.sessionStatus.isBrokerLogin,
    timeLeft: state.UISettings.sessionStatus.timeLeft,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSessionStatus: UIActions.setSessionStatus,
    },
    dispatch,
  );

SessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  timeLeft: PropTypes.number.isRequired,
  isBrokerLogin: PropTypes.bool.isRequired,
  sessionStatus: PropTypes.shape({
    showModal: PropTypes.bool,
    timeLeft: PropTypes.number,
    isBrokerLogin: PropTypes.bool,
  }),
  setSessionStatus: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionModal);
