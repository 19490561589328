import { logEvent, EVENTS } from 'lib/amplitude';
import { signOut, getOktaAuth } from 'lib/okta';
import LocalStorageProxy from 'lib/localStorageProxy';
import { isOneTimePrivacyPolicy } from 'lib/utils/browserUtils';
import { isEmpty } from 'lodash';
import { isApplyNow } from 'lib/envHelper';
import { GET_STARTED_PATH } from 'shared/constants/paths';
import { getAdvisorOrAdvisorOrg } from 'lib/prepareStore';
import { useHistory } from 'react-router-dom';

export const logoutUtil = async (
  logoutRedirectUri,
  oktaAuth = getOktaAuth(),
  showContinueSession = false,
  statusHandler = {},
) => {
  logEvent(EVENTS.LOG_OUT);
  console.log(
    'logoutUtil==',
    logoutRedirectUri,
    oktaAuth,
    showContinueSession,
    statusHandler,
  );

  await signOut(oktaAuth);

  if (logoutRedirectUri) {
    LocalStorageProxy.logoutRedirectUri = logoutRedirectUri;
  }

  if (showContinueSession) {
    LocalStorageProxy.showContinueSession = showContinueSession;
  }

  if (statusHandler.setSessionStatus) {
    statusHandler.setSessionStatus({ showModal: false });
  }

  LocalStorageProxy.clearAll();
  sessionStorage.clear();

  if (logoutRedirectUri) {
    window.location.href = logoutRedirectUri;
    window.location.reload();
  }
};

export const getStoragePath = (origin, pathname) => `${origin}${pathname}#`;

export const setLoginCurrentPath = () => {
  const { origin, pathname } = window.location;
  const redirectPath = getStoragePath(origin, pathname);
  console.log('redirectPath', redirectPath);
  LocalStorageProxy.loginCurrentPath = redirectPath;
};

export const validateLoginCurrentPath = () => {
  const { origin, pathname } = window.location;
  const currentPath = getStoragePath(origin, pathname);
  if (
    LocalStorageProxy.loginCurrentPath &&
    LocalStorageProxy.loginCurrentPath !== currentPath
  ) {
    window.location.replace(LocalStorageProxy.loginCurrentPath);
  }
};

export const redirectionRootPath = (redirectPath, sessionExpired = false) => {
  const { advisorOrgId, advisorOrgSlug, advisorUuid } = getAdvisorOrAdvisorOrg(
    window.location.pathname,
  );
  const orgId = advisorOrgId ? `/${advisorOrgId}` : '';
  const slug = advisorOrgSlug ? `/${advisorOrgSlug}` : '';
  const uuid = advisorUuid ? `/${advisorUuid}` : '';
  let path = isApplyNow() ? `${redirectPath}` : '';

  if (sessionExpired) {
    path = `${redirectPath}`;
  }

  return `${window.location.origin}${orgId}${slug}${uuid}/#${path}`;
};

export const onRefreshExpirationToken = async () => {
  const oktaAuth = getOktaAuth();
  oktaAuth.authStateManager.subscribe(async (authState) => {
    const tokenFromLocalStorage = localStorage.getItem('token');
    const mycrmToken = JSON.parse(localStorage.getItem('mycrm-tokens') || '{}');
    const isSessionExpired =
      !authState.isAuthenticated &&
      !isOneTimePrivacyPolicy() &&
      tokenFromLocalStorage &&
      isEmpty(mycrmToken);

    if (authState.isAuthenticated) {
      const newToken = authState.accessToken?.accessToken;
      LocalStorageProxy.token = newToken;
    }

    if (isSessionExpired) {
      console.log('isSessionExpired', isSessionExpired);
      // await logoutUtil(redirectionRootPath(GET_STARTED_PATH), oktaAuth);
    }
  });
  await oktaAuth.start();
};

export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();
  history.replace(toRelativeUrl(originalUri, window.location.origin));
};

export const isBrokerLogin =
  !!LocalStorageProxy.loginAsClientId ||
  !!LocalStorageProxy.loginAsLoanApplicationId;

export const refreshTokens = async (oktaAuth) => {
  try {
    // First try to refresh just the access token
    const refreshed = await oktaAuth.tokenManager.renew('accessToken');
    if (refreshed) {
      // Update local storage with new token
      LocalStorageProxy.token = refreshed.accessToken;
      // Trigger auth state update
      oktaAuth.authStateManager.updateAuthState();
      return refreshed;
    }

    // If simple refresh fails, try getting new tokens
    const { tokens } = await oktaAuth.token.getWithoutPrompt({
      responseType: 'code',
      scopes: ['openid', 'profile', 'email'],
    });

    await oktaAuth.tokenManager.setTokens(tokens);
    LocalStorageProxy.token = tokens.accessToken?.accessToken;
    oktaAuth.authStateManager.updateAuthState();
    return refreshed;
  } catch (error) {
    console.error('Failed to refresh tokens:', error);
    // If refresh fails completely, throw error for handling by caller
    await logoutUtil(redirectionRootPath(GET_STARTED_PATH), oktaAuth);
  }
};
